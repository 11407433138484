// SpeedDialActions.js
import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Box, Typography } from '@mui/material';

const NoTooltip = ({ children }) => children;

const SpeedDialActions = ({ actions, fabLabel, theme }) => {
  return (
    <SpeedDial
      ariaLabel="Verhaal Acties"
      sx={{
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(2),
        '& .MuiSpeedDial-fab': {
          width: '120px',
          height: '48px',
          borderRadius: '8px',
          padding: theme.spacing(0.5, 2),
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      icon={
        <Typography variant="button" sx={{ fontWeight: 'bold', color: 'white' }}>
          {fabLabel}
        </Typography>
      }
      direction="up"
      FabProps={{ color: 'primary' }}
    >
      {actions.map((action) => {
        // If a customComponent is provided, render it inside a Box.
        if (action.customComponent) {
          return (
            <SpeedDialAction
              key={action.name}
              icon={<Box>{action.customComponent}</Box>}
              tooltipTitle={
                <Typography variant="caption" noWrap>
                  {action.tooltip}
                </Typography>
              }
              tooltipOpen
              FabProps={{ disabled: action.disabled }}
              // Do not attach an onClick here so the custom component handles its own click.
              TooltipComponent={NoTooltip}
            />
          );
        }
        return (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={
              <Typography variant="caption" noWrap>
                {action.tooltip}
              </Typography>
            }
            onClick={action.action}
            tooltipOpen
            FabProps={{ disabled: action.disabled }}
            TooltipComponent={NoTooltip}
          />
        );
      })}
    </SpeedDial>
  );
};

export default SpeedDialActions;
