// DownloadDocxButton.js
import React, { useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Document,
  Packer,
  Paragraph,
  HeadingLevel,
  ImageRun,
  TextRun,
} from 'docx';
import { useTranslation } from 'react-i18next';

function DownloadDocxButton({ uuid, base64Image, originalMarkdown }) {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  const PAGE_WIDTH = 1000;
  const PAGE_MARGIN = 800; // ~3cm margin.
  const AVAILABLE_WIDTH = PAGE_WIDTH - 2 * PAGE_MARGIN; 
  const IMAGE_MAX_WIDTH = 300;

  const fetchImageBase64 = async (imageNumber) => {
    try {
      const res = await fetch(
        `/api/getImageByNumber?uuid=${encodeURIComponent(uuid)}&number=${imageNumber}`
      );
      if (!res.ok) throw new Error(`Error fetching image ${imageNumber}`);
      const data = await res.json();
      if (!data.imageUrl) throw new Error(`No imageUrl for image ${imageNumber}`);
      const cleanUrl = data.imageUrl.split('?')[0];
      const base64Res = await fetch(`/api/getImage?url=${encodeURIComponent(cleanUrl)}`);
      if (!base64Res.ok) throw new Error(`Error fetching base64 for image ${imageNumber}`);
      const base64Data = await base64Res.json();
      return base64Data.base64;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getImageDimensions = (base64) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = () => resolve({ width: 400, height: 300 });
      img.src = base64;
    });

  // Simple markdown parser for **bold** text.
  const parseMarkdown = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return new TextRun({
          text: part.slice(2, -2),
          bold: true,
          font: "Arial",
        });
      }
      return new TextRun({
        text: part,
        font: "Arial",
      });
    });
  };

  const processMarkdownToDocxElements = async () => {
    const regex = /\[IMAGE_(\d+)\]/g;
    let elements = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(originalMarkdown)) !== null) {
      if (match.index > lastIndex) {
        const textSegment = originalMarkdown.substring(lastIndex, match.index).trim();
        if (textSegment) {
          elements.push(
            new Paragraph({
              children: parseMarkdown(textSegment),
              spacing: { line: 360 },
            })
          );
        }
      }
      const imageNumber = match[1];
      const imgBase64 = await fetchImageBase64(imageNumber);
      if (imgBase64) {
        const dims = await getImageDimensions(imgBase64);
        const ratio = IMAGE_MAX_WIDTH / dims.width;
        const scaledWidth = IMAGE_MAX_WIDTH;
        const scaledHeight = dims.height * ratio;
        elements.push(
          new Paragraph({
            alignment: 'left',
            children: [
              new ImageRun({
                data: imgBase64,
                transformation: { width: scaledWidth, height: scaledHeight },
              }),
            ],
          })
        );
      }
      lastIndex = regex.lastIndex;
    }
    if (lastIndex < originalMarkdown.length) {
      const textSegment = originalMarkdown.substring(lastIndex).trim();
      if (textSegment) {
        elements.push(
          new Paragraph({
            children: parseMarkdown(textSegment),
            spacing: { line: 360 },
          })
        );
      }
    }
    return elements;
  };

  const handleDownload = async () => {
    setIsGenerating(true);
    setDownloadError(null);
    try {
      let docChildren = [];

      // Header.
      docChildren.push(
        new Paragraph({
          children: [
            new TextRun({
              text: t('storyDisplay.pdfHeader'),
              bold: true,
              color: "#4B0082",
              font: "Arial",
              size: 64,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
          alignment: 'left',
          spacing: { line: 360 },
        })
      );

      // Top image.
      if (base64Image) {
        const dims = await getImageDimensions(base64Image);
        const ratio = IMAGE_MAX_WIDTH / dims.width;
        const scaledWidth = IMAGE_MAX_WIDTH;
        const scaledHeight = dims.height * ratio;
        docChildren.push(
          new Paragraph({
            alignment: 'left',
            children: [
              new ImageRun({
                data: base64Image,
                transformation: { width: scaledWidth, height: scaledHeight },
              }),
            ],
          })
        );
      }

      // Title.
      docChildren.push(
        new Paragraph({
          children: [
            new TextRun({
              text: t('storyDisplay.pdfTitle'),
              bold: true,
              color: "#4B0082",
              font: "Arial",
              size: 60,
            }),
          ],
          heading: HeadingLevel.HEADING_2,
          alignment: 'left',
          spacing: { line: 360 },
        })
      );

      // Process markdown text and images.
      const markdownElements = await processMarkdownToDocxElements();
      docChildren = [...docChildren, ...markdownElements];

      // Create document with default styles.
      const doc = new Document({
        styles: {
          default: {
            document: {
              run: {
                font: "Arial",
                size: 32,
              },
              paragraph: {
                spacing: { line: 360 },
              },
            },
          },
        },
        sections: [{
          properties: {
            page: {
              margin: {
                top: PAGE_MARGIN,
                right: PAGE_MARGIN,
                bottom: PAGE_MARGIN,
                left: PAGE_MARGIN,
              },
            },
          },
          children: docChildren,
        }],
      });

      const blob = await Packer.toBlob(doc);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'verhaal.docx';
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadError(t('storyDisplay.pdfError'));
      console.error(error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <IconButton onClick={handleDownload} disabled={isGenerating}>
      {isGenerating ? <CircularProgress size={24} /> : <DownloadIcon />}
    </IconButton>
  );
}

export default DownloadDocxButton;
