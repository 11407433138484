
// src/pages/CampagnePage.js

import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Alert,
  Card,
  CardContent,
  Paper,
  Divider,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

const HeroBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(/images/hero-campagne.jpg)', // replace with your own image path
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(8, 2),
  color: '#fff',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const CampagnePage = () => {
  const [formData, setFormData] = useState({
    naamLeraar: '',
    email: '',
    telefoon: '',
    naamSchool: '',
    adresSchool: '',
    aantalLeerlingen: '',
    typeOnderwijs: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/api/send-form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (!response.ok) {
        setErrorMessage(data.error || 'Er is een fout opgetreden. Probeer het opnieuw.');
      } else {
        setSuccessMessage(data.message || 'Aanmelding succesvol verzonden!');
        setFormData({
          naamLeraar: '',
          email: '',
          telefoon: '',
          naamSchool: '',
          adresSchool: '',
          aantalLeerlingen: '',
          typeOnderwijs: '',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <HeroBox>
        <Typography variant="h3" component="h1" gutterBottom>
          Verhalen-maker Leesuitdaging
        </Typography>
      </HeroBox>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
      <Typography variant="h5">
          Meld je school aan en ontdek hoe leuk lezen kan zijn!
        </Typography>
        <Typography variant="h5" gutterBottom>
          Voorwaarden voor deelname
        </Typography>

        <Box sx={{ pl: 1 }}>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            1. Doelgroep
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4, mb: 2 }}>
            <li>Deelname is uitsluitend voor basisscholen en middelbare scholen in Nederland.</li>
            <li>Elke school kan slechts één klas selecteren om deel te nemen.</li>
          </Typography>

          <Typography variant="subtitle1">
            2. Inschrijving &amp; deelname
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4, mb: 2 }}>
            <li>Scholen melden zich aan via een reactie op de ontvangen e-mail en ontvangen een unieke registratiecode.</li>
            <li>De geselecteerde klas krijgt één week lang ongelimiteerde toegang tot het Verhalen-maker platform (onder fair-use-voorwaarden).</li>
            <li>De proefweek start zodra de registratiecode wordt ingevoerd, binnen de aangegeven actieperiode.</li>
            <li>Na deze proefperiode kiest de klas samen het meest inspirerende verhaal en stuurt dit in.</li>
          </Typography>

          <Typography variant="subtitle1">
            3. Selectie van de winnaars
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4, mb: 2 }}>
            <li>
              <strong>Winnende school:</strong><br />
              Verhalen worden beoordeeld op originaliteit, creativiteit en leesmotivatie. De onafhankelijke jury (onderwijsexperts en het Verhalen-maker team) kiest de uiteindelijke winnaar.
            </li>
            <li>
              <strong>Winnende leerling:</strong><br />
              De leerling die het leukste, creatiefste en best geschreven verhaal heeft gemaakt, wint een prijs. De jury beoordeelt het verhaal op originaliteit, structuur en taalgebruik.
            </li>
          </Typography>

          <Typography variant="subtitle1">
            4. Prijzen
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4, mb: 2 }}>
            <li>
              <strong>Voor de winnende school:</strong><br />
              3 maanden lang 35% korting op een 1-jarige schoollicentie.
            </li>
            <li>
              <strong>Voor de winnende leerling:</strong><br />
              1 jaar gratis toegang tot het Verhalen-maker platform.
            </li>
          </Typography>

          <Typography variant="subtitle1">
            5. Deadline &amp; bekendmaking
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4, mb: 2 }}>
            <li>Uiterlijk 17 maart – Aanmelddeadline</li>
            <li>1 week toegang tot het platform (vanaf registratie)</li>
            <li>25 t/m 31 maart (uiterlijk 16:00) – Insturen geselecteerd verhaal</li>
            <li>14 april – Bekendmaking van de winnende school en leerling via e-mail en onze website</li>
          </Typography>

          <Typography variant="subtitle1">
            6. Overige bepalingen
          </Typography>
          <Typography variant="body2" component="ul" sx={{ pl: 4 }}>
            <li>Door deelname geeft de school toestemming om anonieme resultaten en feedback te gebruiken voor promotionele doeleinden.</li>
            <li>De organisatie behoudt zich het recht voor om de actie tussentijds aan te passen of te beëindigen indien noodzakelijk.</li>
            <li>De prijs voor de winnende leerling is persoonlijk en niet overdraagbaar.</li>
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">
          Wil jouw school meedoen? Meld je aan vóór 17 maart en ontdek hoe leuk lezen kan zijn!
        </Typography>
      </Paper>

      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

      <Card elevation={4} sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Aanmeldformulier
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Naam leraar/lerares"
                  name="naamLeraar"
                  value={formData.naamLeraar}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="E-mailadres"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Telefoonnummer"
                  name="telefoon"
                  type="tel"
                  value={formData.telefoon}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Naam school"
                  name="naamSchool"
                  value={formData.naamSchool}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Adres school"
                  name="adresSchool"
                  value={formData.adresSchool}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Aantal leerlingen"
                  name="aantalLeerlingen"
                  type="number"
                  value={formData.aantalLeerlingen}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Type onderwijs"
                  name="typeOnderwijs"
                  value={formData.typeOnderwijs}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="basisonderwijs">Basisonderwijs</MenuItem>
                  <MenuItem value="voortgezetonderwijs">Voortgezet onderwijs</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Verzenden...' : 'Verstuur'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CampagnePage;
