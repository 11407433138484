import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const UserStatusContext = createContext();

const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const UserStatusProvider = ({ children }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [userCredits, setUserCredits] = useState(null);
  const [totalStories, setTotalStories] = useState(null);
  const [totalQuizzes, setTotalQuizzes] = useState(null);
  const [totalQuizPoints, setTotalQuizPoints] = useState(null);
  const location = useLocation();

  const fetchUserStatus = async () => {
    if (isAuthenticated && user) {
      try {
        // console.log(user)

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: 'read:current_user',
          },
          cacheMode: 'on',
        });
        const headers = { 'Content-Type': 'application/json' };
        if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;

        // Access additional value from localStorage
        const utmSource = localStorage.getItem('utm_source');

        const { data } = await axios.post(
          '/api/user-status',
          { email: user.email, sub: user.sub, utm_source: utmSource },
          { headers }
        );

        const {
          user_subscription_status,
          user_credits,
          total_stories,
          total_quizzes,
          total_quiz_points,
        } = data;

        setSubscriptionStatus(user_subscription_status);
        setUserCredits(user_credits);
        setTotalStories(total_stories);
        setTotalQuizzes(total_quizzes);
        setTotalQuizPoints(total_quiz_points);

        console.log('User Subscription Status:', user_subscription_status);
        console.log('User Credits:', user_credits);
        console.log('Total Stories:', total_stories);
        console.log('Total Quizzes:', total_quizzes);
        console.log('Total Quiz Points:', total_quiz_points);
      } catch (error) {
        console.error('Error fetching user status:', error);
        setSubscriptionStatus(null);
        setUserCredits(null);
        setTotalStories(null);
        setTotalQuizzes(null);
        setTotalQuizPoints(null);
      }
    } else {
      setSubscriptionStatus(null);
      setUserCredits(null);
      setTotalStories(null);
      setTotalQuizzes(null);
      setTotalQuizPoints(null);
    }
  };

  useEffect(() => {
    fetchUserStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, getAccessTokenSilently, AUTH0_AUDIENCE, location.pathname]);

  return (
    <UserStatusContext.Provider
      value={{
        subscriptionStatus,
        userCredits,
        totalStories,
        totalQuizzes,
        totalQuizPoints,
        refreshUserStatus: fetchUserStatus,
      }}
    >
      {children}
    </UserStatusContext.Provider>
  );
};
