// StoryDisplay.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Fade,
  CircularProgress,
  LinearProgress,
  Container,
  useTheme,
  useMediaQuery,
  IconButton,
  ButtonGroup,
} from '@mui/material';
import { keyframes } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserStatusContext } from '../../contexts/UserStatusContext';
import { fetch_story_by_uuid, enhance_story } from '../../services/StoryService';
import { showLoginModal } from '../LoginPromptModal';
import { useAbonnementenModal } from '../AbonnementenModal';
import { addCreateStoryTimestamp } from '../../utils/rateLimiter';
import ReCAPTCHAComponent from '../../pages/homepage_components/ReCAPTCHAComponent';

import ImageSection from './ImageSection';
import EmailModal from './EmailModal';
import SpeedDialActions from './SpeedDialActions';
import SnackbarAlert from './SnackbarAlert';
import EnhanceButton from './EnhanceButton';
import ImageLoader from './ImageLoader';

import DownloadPdfButton from './DownloadPdfButton';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import EnhanceIcon from '@mui/icons-material/Build';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import QuizComponent from './QuizComponent';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';

// ----------------- ANIMATION KEYFRAMES -----------------
const pulsate = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(103, 58, 183, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0);
  }
`;

// ----------------- UTILS -----------------
function stripMarkdown(md) {
  return md
    .replace(/\*\*(.*?)\*\*/g, '$1')
    .replace(/__(.*?)__/g, '$1')
    .replace(/`([^`]+)`/g, '$1')
    .replace(/\*\*/g, '')
    .replace(/__/g, '')
    .replace(/#+/g, '')
    .replace(/\*/g, '')
    .replace(/_/g, '');
}

function splitIntoSentences(text) {
  const regex = /[^.!?]+[.!?]+[\])'"`’”]*|.+/g;
  return text.match(regex) || [];
}

// ----------------- TTS PLAYER -----------------
function TTSPlayer({ storyText }) {
  const { t } = useTranslation();
  const cleanedText = stripMarkdown(storyText).replace(/\[IMAGE[^\]]*\]/gi, '');
  const sentences = splitIntoSentences(cleanedText);
  const groups = [];
  for (let i = 0; i < sentences.length; i += 5) {
    groups.push(sentences.slice(i, i + 5).join(' '));
  }

  const [audioBlobs, setAudioBlobs] = useState(Array(groups.length).fill(null));
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [status, setStatus] = useState('');
  const audioPlayerRef = useRef(null);

  const fetchGroupAudio = async (index) => {
    if (audioBlobs[index]) return audioBlobs[index];
    if (!groups[index]) return null;
    try {
      const response = await fetch('/synthesize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: groups[index].trim() }),
      });
      if (!response.ok) return null;
      const blob = await response.blob();
      setAudioBlobs((prev) => {
        const newBlobs = [...prev];
        newBlobs[index] = blob;
        return newBlobs;
      });
      return blob;
    } catch (error) {
      return null;
    }
  };

  const playGroup = async (index) => {
    if (!audioPlayerRef.current) return;
    const blob = await fetchGroupAudio(index);
    if (!blob) return;
    const audioUrl = URL.createObjectURL(blob);
    audioPlayerRef.current.src = audioUrl;
    audioPlayerRef.current
      .play()
      .then(() => {
        setIsPlaying(true);
        setIsPaused(false);
        setCurrentGroupIndex(index);
        if (index + 1 < groups.length) {
          fetchGroupAudio(index + 1);
        }
      })
      .catch(() => {
        setStatus(t('tts.errorPlayingAudio'));
      });
  };

  const handleAudioEnded = () => {
    if (audioPlayerRef.current && audioPlayerRef.current.src) {
      URL.revokeObjectURL(audioPlayerRef.current.src);
      audioPlayerRef.current.src = '';
    }
    const nextIndex = currentGroupIndex + 1;
    if (nextIndex < groups.length) {
      playGroup(nextIndex);
    } else {
      setIsPlaying(false);
      setStatus(t('tts.storyFinished'));
      resetPlayer();
    }
  };

  const resetPlayer = () => {
    setIsPlaying(false);
    setIsPaused(false);
    setCurrentGroupIndex(0);
    setStatus('');
  };

  const startPlaying = async () => {
    if (isPlaying || !groups.length) return;
    await playGroup(0);
  };

  const stopPlaying = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.currentTime = 0;
      audioPlayerRef.current.src = '';
    }
    resetPlayer();
  };

  const pausePlaying = () => {
    if (!audioPlayerRef.current || !isPlaying) return;
    audioPlayerRef.current.pause();
    setIsPaused(true);
    setStatus(t('tts.paused'));
  };

  const resumePlaying = () => {
    if (!audioPlayerRef.current || !isPlaying) return;
    audioPlayerRef.current
      .play()
      .then(() => {
        setIsPaused(false);
        setStatus(t('tts.groupResumed', { group: currentGroupIndex + 1 }));
      })
      .catch(() => {
        setStatus(t('tts.errorResumingAudio'));
      });
  };

  if (!storyText || !groups.length) return null;

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <ButtonGroup sx={{ mb: 1 }}>
        <IconButton
          color="primary"
          onClick={isPlaying ? stopPlaying : startPlaying}
          disabled={!groups.length}
          aria-label={isPlaying ? t('tts.stopAudio') : t('tts.startAudio')}
        >
          {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton
          color="primary"
          disabled={!isPlaying}
          onClick={isPaused ? resumePlaying : pausePlaying}
          aria-label={isPaused ? t('tts.resumeAudio') : t('tts.pauseAudio')}
        >
          {isPaused ? <PlayCircleIcon /> : <PauseIcon />}
        </IconButton>
      </ButtonGroup>
      {status && (
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {status}
        </Typography>
      )}
      <audio ref={audioPlayerRef} onEnded={handleAudioEnded} style={{ display: 'none' }} />
    </Box>
  );
}

// ----------------- MEMOIZED MARKDOWN RENDERER -----------------
const StoryContent = React.memo(function StoryContent({ markdown, uuid }) {
  const components = {
    p: ({ node, children, ...props }) => {
      const imageTagRegex = /^\[IMAGE_(\d+)\]$/;
      const childText = children[0];
      if (typeof childText === 'string') {
        const match = childText.trim().match(imageTagRegex);
        if (match) {
          const imageNumber = parseInt(match[1], 10);
          return (
            <ImageLoader key={`image-${imageNumber}`} uuid={uuid} imageNumber={imageNumber} />
          );
        }
      }
      return (
        <Typography component="p" {...props} sx={{ mb: 2 }}>
          {children}
        </Typography>
      );
    },
  };
  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
    </Box>
  );
});

// ----------------- MAIN STORYDISPLAY -----------------
function StoryDisplay() {
  const { t, i18n } = useTranslation();
  const { uuid } = useParams();
  const [originalMarkdown, setOriginalMarkdown] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [base64Image, setBase64Image] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [loadingStory, setLoadingStory] = useState(true);
  const [storyError, setStoryError] = useState(null);
  const [isPremiumStory, setIsPremiumStory] = useState(false);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailErrorState, setEmailErrorState] = useState(null);

  const [fadeIn, setFadeIn] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [enhanceError, setEnhanceError] = useState(null);

  // Download states (no longer used directly here)
  const [isGenerating, setIsGenerating] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  const recaptchaRef = useRef(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN;
  const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const { userCredits, subscriptionStatus } = useContext(UserStatusContext);
  const { openAbonnementenModal, AbonnementenModal } = useAbonnementenModal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ----------------- FETCH STORY -----------------
  useEffect(() => {
    setFadeIn(false);
    const fetchStory = async () => {
      try {
        const { email, story, image_url, ispremium } = await fetch_story_by_uuid(uuid);
        setOriginalMarkdown(story || '');
        setImageUrl((image_url || '').split('?')[0]);
        setEmail(email || '');
        setIsPremiumStory(ispremium || false);
        setLoadingStory(false);
        ReactGA.event({
          category: 'StoryDisplay',
          action: 'created_story_displayed',
          label: 'Story Display Page Loaded',
        });
      } catch (error) {
        setStoryError(error.message);
        setLoadingStory(false);
      }
    };
    fetchStory();
  }, [uuid]);

  useEffect(() => {
    if (!loadingStory && !storyError) {
      setFadeIn(true);
    }
  }, [loadingStory, storyError]);

  // ----------------- FETCH IMAGE BASE64 -----------------
  useEffect(() => {
    const fetchBase64Image = async () => {
      if (!imageUrl) return;
      setLoadingImage(true);
      setImageError(false);
      try {
        const response = await fetch(`/api/getImage?url=${encodeURIComponent(imageUrl)}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || t('storyDisplay.imageFetchError'));
        }
        const data = await response.json();
        setBase64Image(data.base64);
      } catch (error) {
        setImageError(true);
      } finally {
        setLoadingImage(false);
      }
    };
    fetchBase64Image();
  }, [imageUrl, t]);

  // ----------------- SHARE / EMAIL / ENHANCE -----------------
  const handleShare = () => {
    const shareUrl = `https://${WEBSITE_DOMAIN}/story/${uuid}`;
    const text = encodeURIComponent(`${t('storyDisplay.shareText')} ${shareUrl}`);
    window.open(`https://wa.me/?text=${text}`, '_blank');
    ReactGA.event({
      category: 'User Interaction',
      action: 'share_whatsapp_clicked',
      label: 'User shared story via WhatsApp',
    });
  };

  const handleOpenEmailModal = () => {
    setEmail('');
    setEmailSuccess(null);
    setEmailErrorState(null);
    setEmailModalOpen(true);
    ReactGA.event({
      category: 'User Interaction',
      action: 'send_email_clicked',
      label: 'User clicked send email button',
    });
  };

  const handleCloseEmailModal = () => setEmailModalOpen(false);

  const handleSendEmail = async () => {
    setEmailSending(true);
    setEmailSuccess(null);
    setEmailErrorState(null);
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error(t('storyDisplay.invalidEmail'));
      }
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, uuid }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t('storyDisplay.emailSendError'));
      }
      const data = await response.json();
      setEmailSuccess(data.message || t('storyDisplay.emailSuccess'));
    } catch (error) {
      setEmailErrorState(error.message);
    } finally {
      setEmailSending(false);
    }
  };

  const handleEnhanceStory = () => {
    if (!isAuthenticated) {
      showSnackbar(t('storyDisplay.enhanceNotAuthenticated'), 'info');
      showLoginModal();
      return;
    }
    if (email && user.email !== email) {
      showSnackbar(t('storyDisplay.enhanceNotOwner'), 'error');
      return;
    }
    if (subscriptionStatus !== 'premium') {
      showSnackbar(t('storyDisplay.enhanceNoCredits'), 'info');
      openAbonnementenModal();
      return;
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const onReCAPTCHAChange = async (token) => {
    if (!token) {
      setEnhanceError(t('storyDisplay.captchaNotCompleted'));
      return;
    }
    setIsEnhancing(true);
    setEnhanceError(null);
    try {
      let accessToken = '';
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_AUDIENCE,
              scope: 'read:current_user',
            },
          });
        } catch (tokenError) {
          showSnackbar(t('storyDisplay.authFailed'), 'error');
          setIsEnhancing(false);
          return;
        }
      }
      addCreateStoryTimestamp();
      const currentLanguage = localStorage.getItem('selectedLanguage') || i18n.language;
      await enhance_story(
        { email, uuid, captchaToken: token, isPremium: isPremiumStory, language: currentLanguage },
        accessToken
      );
      window.location.reload();
    } catch (error) {
      setEnhanceError(error.message);
      showSnackbar(error.message || t('storyDisplay.unexpectedError'), 'error');
    } finally {
      setIsEnhancing(false);
    }
  };

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // ----------------- SPEED DIAL ACTIONS -----------------
  // The download action now uses a customComponent that renders DownloadPdfButton.
  const speedDialActions = [
    {
      customComponent: (
        <DownloadPdfButton
          uuid={uuid}
          base64Image={base64Image}
          originalMarkdown={originalMarkdown}
        />
      ),
      name: t('storyDisplay.actions.download'),
      tooltip: t('storyDisplay.actions.downloadTooltip'),
    },
    {
      icon: <WhatsAppIcon />,
      name: t('storyDisplay.actions.share'),
      action: handleShare,
      tooltip: t('storyDisplay.actions.shareTooltip'),
    },
    {
      icon: <EmailIcon />,
      name: t('storyDisplay.actions.email'),
      action: handleOpenEmailModal,
      tooltip: t('storyDisplay.actions.emailTooltip'),
    },
    {
      icon: isEnhancing ? <CircularProgress size={24} /> : <EnhanceIcon />,
      name: t('storyDisplay.actions.enhance'),
      action: handleEnhanceStory,
      tooltip: t('storyDisplay.actions.enhanceTooltip'),
      disabled: isEnhancing,
    },
  ];

  return (
    <Fade in={fadeIn} timeout={500}>
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: { xs: 2, sm: 4 },
        }}
      >
        {loadingStory ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : storyError ? (
          <Typography color="error" align="center" sx={{ my: 4 }}>
            {storyError}
          </Typography>
        ) : (
          <>
            {/* TITLE */}
            <Typography variant="h4" align="center" gutterBottom>
              {t('storyDisplay.title')}
            </Typography>

            {/* TOP IMAGE SECTION */}
            <ImageSection
              loadingImage={loadingImage}
              imageError={imageError}
              base64Image={base64Image}
              imageUrl={imageUrl}
              isPremiumStory={isPremiumStory}
            />

            {/* TTS PLAYER */}
            <TTSPlayer storyText={originalMarkdown} />

            {/* MEMOIZED MARKDOWN RENDER */}
            <StoryContent markdown={originalMarkdown} uuid={uuid} />

            {/* QUIZ COMPONENT */}
            <QuizComponent uuid={uuid} />

            {/* ENHANCE BUTTON & PROGRESS */}
            <EnhanceButton
              isEnhancing={isEnhancing}
              handleEnhanceStory={handleEnhanceStory}
              pulsate={pulsate}
            />
            {isEnhancing && (
              <Box sx={{ width: '100%', my: 2 }}>
                <LinearProgress />
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                  {t('storyDisplay.enhanceProgress')}
                </Typography>
              </Box>
            )}
          </>
        )}

        {/* SPEED DIAL */}
        <SpeedDialActions actions={speedDialActions} fabLabel={t('storyDisplay.fabLabel')} theme={theme} />

        {/* EMAIL MODAL */}
        <EmailModal
          email={email}
          setEmail={setEmail}
          open={emailModalOpen}
          handleClose={handleCloseEmailModal}
          handleSendEmail={handleSendEmail}
          emailSending={emailSending}
          emailSuccess={emailSuccess}
          emailError={emailErrorState}
        />

        {/* SNACKBAR */}
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleCloseSnackbar}
        />

        {/* RECAPTCHA FOR ENHANCE */}
        <Box sx={{ mt: 4 }}>
          <ReCAPTCHAComponent recaptchaRef={recaptchaRef} onReCAPTCHAChange={onReCAPTCHAChange} />
        </Box>

        {/* ABONNEMENTEN MODAL */}
        {AbonnementenModal}
      </Container>
    </Fade>
  );
}

export default StoryDisplay;
