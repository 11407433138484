import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Avatar,
  Tooltip,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Badge,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateIcon from '@mui/icons-material/Create';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Added for Wedstrijd
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { UserStatusContext } from '../contexts/UserStatusContext';
import { useAbonnementenModal } from './AbonnementenModal';
import BrandSelectionModal from '../components/BrandSelectionModal';
import { useBrand } from '../contexts/BrandContext';

const drawerWidth = 240;

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userCredits, setUserCredits] = useState(null);

  // For language picklist
  const [language, setLanguage] = useState(i18n.language || 'en');
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  useEffect(() => {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      setLanguage(savedLang);
      changeLanguage(savedLang);
    }
  }, []);

  const { openAbonnementenModal } = useAbonnementenModal();
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const { subscriptionStatus, userCredits: contextUserCredits } = useContext(UserStatusContext);
  useEffect(() => {
    setUserCredits(contextUserCredits);
  }, [contextUserCredits]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  const handleNavigateBibliotheek = () => {
    navigate('/mijn-verhalen-bibliotheek');
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (subscriptionStatus === 'unverified') {
      // openAbonnementenModal();
    }
  }, [subscriptionStatus, openAbonnementenModal]);

  const { brand } = useBrand();
  const [openBrandModal, setOpenBrandModal] = useState(false);

  useEffect(() => {
    // Uncomment below if you want to force reselecting the brand:
    // setOpenBrandModal(!brand);
  }, [brand]);

  useEffect(() => {
    if (userCredits === 0 && subscriptionStatus !== 'premium') {
      const modalShown = localStorage.getItem('creditsModalShown');
      if (!modalShown) {
        openAbonnementenModal();
        localStorage.setItem('creditsModalShown', 'true');
      }
    }
  }, [userCredits, subscriptionStatus, openAbonnementenModal]);

  const handleReload = () => {
    window.location.reload();
  };

  const handleLogin = () => {
    sessionStorage.setItem('returnTo', location.pathname + location.search);
    loginWithRedirect().catch((error) => {
      console.error('Navigation: loginWithRedirect error:', error);
    });
  };

  const drawer = (
    <Box
      onClick={isMobile ? handleDrawerToggle : undefined}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Box>
        <List>
          <ListItem
            button
            component={NavLink}
            to="/"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawer.createStory')} />
          </ListItem>

          {/* Added Wedstrijd option */}
          <ListItem
            button
            component={NavLink}
            to="/wedstrijd"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <EmojiEventsIcon />
            </ListItemIcon>
            <ListItemText primary="Wedstrijd" />
          </ListItem>

          {isAuthenticated && (
            <ListItem
              button
              component={NavLink}
              to="/mijn-verhalen-bibliotheek"
              sx={{
                '&.active': {
                  backgroundColor: theme.palette.action.selected,
                },
              }}
            >
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary={t('drawer.library')} />
            </ListItem>
          )}

          <Divider sx={{ my: 1 }} />

          <ListItem
            button
            component={NavLink}
            to="/veel-gestelde-vragen"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawer.faq')} />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to="/contact"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawer.contact')} />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            to="/over-ons"
            sx={{
              '&.active': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawer.aboutUs')} />
          </ListItem>

          {isAuthenticated && (
            <ListItem
              button
              onClick={openAbonnementenModal}
              sx={{
                '&.active': {
                  backgroundColor: theme.palette.action.selected,
                },
              }}
            >
              <ListItemIcon>
                <SubscriptionsIcon />
              </ListItemIcon>
              <ListItemText primary={t('drawer.bundles')} />
            </ListItem>
          )}

          <Divider sx={{ my: 1 }} />
        </List>
      </Box>
      <Box sx={{ mt: 'auto', mb: 2, px: 2 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="language-select-label" sx={{ fontSize: '0.875rem' }}>
            {t('Header.Languagelabel', 'Language')}
          </InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={language}
            onChange={handleLanguageChange}
            label="Language"
            sx={{ fontSize: '0.875rem' }}
          >
            <MenuItem value="en">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="https://flagcdn.com/gb.svg"
                  alt="English Flag"
                  style={{ width: '24px', marginRight: 8 }}
                />
                English
              </Box>
            </MenuItem>
            <MenuItem value="nl">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="https://flagcdn.com/nl.svg"
                  alt="Dutch Flag"
                  style={{ width: '24px', marginRight: 8 }}
                />
                Nederlands
              </Box>
            </MenuItem>
            <MenuItem value="de">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="https://flagcdn.com/de.svg"
                  alt="German Flag"
                  style={{ width: '24px', marginRight: 8 }}
                />
                Deutsch
              </Box>
            </MenuItem>
            <MenuItem value="fr">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="https://flagcdn.com/fr.svg"
                  alt="French Flag"
                  style={{ width: '24px', marginRight: 8 }}
                />
                Français
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {isAuthenticated && (
            <Tooltip title={t('header.libraryTooltip')}>
              <IconButton
                color="inherit"
                aria-label="open stories library"
                onClick={handleNavigateBibliotheek}
                sx={{ mr: 2 }}
              >
                <LibraryBooksIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('header.homeTooltip')}>
            <IconButton
              color="inherit"
              aria-label="home"
              onClick={handleNavigateHome}
              sx={{ mr: 2 }}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>

          {isAuthenticated && (
            <Tooltip title={t('header.bundlesTooltip')}>
              <IconButton
                color="inherit"
                aria-label="bundles"
                onClick={openAbonnementenModal}
                sx={{ mr: 2 }}
              >
                <SubscriptionsIcon />
              </IconButton>
            </Tooltip>
          )}

          <Typography
            variant="h6"
            component="div"
            sx={{
              color: 'inherit',
              cursor: 'pointer',
              display: isMobile ? 'none' : 'block',
            }}
            onClick={handleNavigateHome}
          >
            {t('header.title')}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {isAuthenticated && user ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={user.name}>
                <IconButton sx={{ p: 0, mr: 1 }}>
                  {userCredits >= 1 ? (
                    <Badge
                      badgeContent={userCredits}
                      color="success"
                      max={9999}
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <Avatar alt={user.name} src={user.picture} />
                    </Badge>
                  ) : (
                    <Avatar alt={user.name} src={user.picture} />
                  )}
                </IconButton>
              </Tooltip>

              {subscriptionStatus === 'premium' && (
                <Tooltip title="Premium user">
                  <span role="img" aria-label="premium">
                    👑
                  </span>
                </Tooltip>
              )}
              <Button color="inherit" onClick={handleLogout}>
                {t('header.logOut')}
              </Button>
            </Box>
          ) : (
            <Button color="inherit" onClick={handleLogin}>
              {t('header.logInOrSignUp')}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
          style: { zIndex: theme.zIndex.drawer + 2 },
        }}
        sx={{
          display: { xs: 'block', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>

      <Toolbar sx={{ display: { xs: 'none', md: 'none' } }} />
      <BrandSelectionModal
        open={openBrandModal}
        onClose={(selected) => {
          if (selected) setOpenBrandModal(false);
        }}
      />
      <Dialog
        open={subscriptionStatus === 'unverified'}
        onClose={() => {}}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          {t('unverifiedDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="verification-dialog-description">
            {t('unverifiedDialog.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReload} color="primary" variant="contained">
            {t('unverifiedDialog.refreshButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Navigation;
