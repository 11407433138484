// src/App.js

import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import OverOnsPage from './pages/OverOnsPage';
import SessionPage from './pages/SessionPage';
import NotFoundPage from './pages/NotFoundPage';
import StoryDisplay from './components/StoryDisplay/StoryDisplay';
import Contact from './pages/Contact';
import { UUIDProvider } from './contexts/UUIDContext';
import ReactGA from 'react-ga4';
import LoginPromptModal from './components/LoginPromptModal';
import VeelGesteldeVragen from './pages/veelGesteldeVragen';
import BetalingGedaan from './pages/BetalingGedaan';
import AanvragenKlassenlink from './pages/CampagnePage';
import MijnVerhalenBibliotheek from './pages/MijnVerhalenBibliotheek';
import { useAuth0 } from '@auth0/auth0-react';
import TagManager from 'react-gtm-module';
import AbonnementenModalProvider from './components/AbonnementenModal';
import { UserStatusProvider } from './contexts/UserStatusContext';
import './index.css';
import AlgemeneVoorwaardenPage from './pages/AlgemeneVoorwaardenPage';
import PrivacybeleidPage from './pages/PrivacybeleidPage';

// ========== NEW IMPORTS ==========
import { useTranslation } from 'react-i18next';
import { useBrand } from './contexts/BrandContext';
import BasisschoolRedirect from './pages/BasisschoolRedirect'; 
import MiddelbareSchoolRedirect from './pages/MiddelbareSchoolRedirect';
// ====================================

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-YE9Z8MFMJD';
const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-TR7MX75R';

ReactGA.initialize(TRACKING_ID);

const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('GAListener: Tracking page view:', location.pathname + location.search);
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

const QrCodeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('QrCodeRedirect: Logging QR code visit event');
    ReactGA.event({
      category: 'QR Code',
      action: 'qr_code_visit',
      label: 'QR Code Page Visited',
    });

    console.log('QrCodeRedirect: Redirecting to home page');
    navigate('/');
  }, [navigate]);

  return null;
};

const AppRoutes = () => (
  <Routes>
    {/* If someone visits /basisschool or /middelbareschool, set the brand instantly */}
    <Route path="/basisschool" element={<BasisschoolRedirect />} />
    <Route path="/middelbareschool" element={<MiddelbareSchoolRedirect />} />

    <Route path="/" element={<HomePage />} />
    <Route path="/over-ons" element={<OverOnsPage />} />
    <Route path="/session/:id" element={<SessionPage />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/story/:uuid" element={<StoryDisplay />} />
    <Route path="/qrcode" element={<QrCodeRedirect />} />
    <Route path="/veel-gestelde-vragen" element={<VeelGesteldeVragen />} />
    <Route path="/mijn-verhalen-bibliotheek" element={<MijnVerhalenBibliotheek />} />
    <Route path="/wedstrijd" element={<AanvragenKlassenlink />} />
    <Route path="/betaling-gedaan" element={<BetalingGedaan />} />
    <Route path="/algemene-voorwaarden" element={<AlgemeneVoorwaardenPage />} />
    <Route path="/privacybeleid" element={<PrivacybeleidPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

const App = () => {
  const { isAuthenticated, user, isLoading, error } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // Language auto-selection based on URL parameter (or saved language)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langFromUrl = params.get('lang');
    if (langFromUrl) {
      i18n.changeLanguage(langFromUrl);
      localStorage.setItem('selectedLanguage', langFromUrl);
    } else {
      const savedLang = localStorage.getItem('selectedLanguage');
      if (savedLang) {
        i18n.changeLanguage(savedLang);
      }
    }
  }, [location.search, i18n]);

  // Capture UTM parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get('utm_source');
    if (utmSource) {
      localStorage.setItem('utm_source', utmSource);
      // Capture additional UTM params as needed
    }
  }, [location.search]);

  // ========== Access the brand from context ==========
  const { brand } = useBrand();
  const showBrandModal = brand === '';
  console.log("Current brand:", brand, typeof brand);
  // ====================================================

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useEffect(() => {
    if (isLoading) {
      console.log('App: Authentication is loading...');
    } else if (error) {
      console.error('App: Authentication error:', error);
    } else if (isAuthenticated) {
      console.log('App: User is authenticated:', user);
      console.log('App: Current location:', location.pathname + location.search);

      const returnTo = sessionStorage.getItem('returnTo');
      if (returnTo && returnTo !== location.pathname + location.search) {
        console.log(`App: Redirecting to stored path: ${returnTo}`);
        navigate(returnTo, { replace: true });
        sessionStorage.removeItem('returnTo');
      }
    } else {
      console.log('App: User is not authenticated');
    }
  }, [isAuthenticated, user, isLoading, error, location, navigate]);

  return (
    <UUIDProvider>
      <UserStatusProvider>
        <AbonnementenModalProvider>
          <GAListener />

          {/* Our normal top navigation */}
          <Navigation />

          {/* Main Content - behind the modal if it's open */}
          <div style={{ paddingBottom: '64px' }}>
            <AppRoutes />
          </div>

          <LoginPromptModal />
        </AbonnementenModalProvider>
      </UserStatusProvider>
    </UUIDProvider>
  );
};

export default App;
