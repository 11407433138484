// src/components/AbonnementenModal.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Collapse,
  useTheme,
  Chip,
  Slide,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Dialog as MuiDialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { UserStatusContext } from '../contexts/UserStatusContext';
import { useTranslation } from 'react-i18next';

const AbonnementenModalContext = createContext();
export const useAbonnementenModal = () =>
  useContext(AbonnementenModalContext);

export const AbonnementenModalProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { userCredits } = useContext(UserStatusContext);
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [daysUntilRefill, setDaysUntilRefill] = useState(null);
  const [orgExpanded, setOrgExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // 0 for One-Time, 1 for Subscription

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
  });

  const [isCancelling, setIsCancelling] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    email: '',
  });

  // Get translated reviews from i18next (should be an array of objects)
  const reviews = t('abonnementenModal.reviews', { returnObjects: true });
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const userName =
    user?.name?.split(' ')[0] ||
    t('abonnementenModal.defaultUser', { defaultValue: 'lezer' });

  useEffect(() => {
    const calcDaysUntilRefill = () => {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const diffTime = nextMonth - today;
      setDaysUntilRefill(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    };
    calcDaysUntilRefill();
    const refillInterval = setInterval(calcDaysUntilRefill, 1000 * 60 * 60 * 24);
    return () => clearInterval(refillInterval);
  }, []);

  useEffect(() => {
    if (!reviews || reviews.length === 0) return;
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * reviews.length);
      setCurrentReviewIndex(randomIndex);
    }, 5000);
    return () => clearInterval(interval);
  }, [reviews]);

  const openAbonnementenModal = () => {
    ReactGA.event({
      category: 'Modal',
      action: 'AboModalOpened',
      label: 'Subscription modal opened',
    });
    setIsModalOpen(true);
  };

  // Now simply close the modal immediately
  const closeAbonnementenModal = () => {
    setIsModalOpen(false);
  };

  // Handler for One-Time Payment (Kindbundel)
  const handleChooseKindbundel = async () => {
    if (!isAuthenticated || !user) {
      alert(t('abonnementenModal.notAuthenticatedBundle'));
      return;
    }
    try {
      const response = await axios.post('/initiate_payment', {
        type: 'premium_child',
        email: user.email,
      });
      window.location.href = response.data.payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert(t('abonnementenModal.paymentError'));
    }
  };

  // Handler for Subscription Payments
  const handleChooseSubscription = async (type) => {
    if (!isAuthenticated || !user) {
      alert(t('abonnementenModal.notAuthenticatedSubscription'));
      return;
    }
    try {
      const response = await axios.post('/initiate_payment', {
        type: type, // 'monthly_license' or 'yearly_license'
        email: user.email,
      });
      window.location.href = response.data.payment_url;
    } catch (error) {
      console.error('Error initiating subscription payment:', error);
      alert(t('abonnementenModal.paymentError'));
    }
  };

  // Handler for Cancel Subscription
  const handleCancelSubscription = async () => {
    if (!isAuthenticated || !user) {
      alert(t('abonnementenModal.notAuthenticatedCancel'));
      return;
    }
    // Open confirmation dialog
    setConfirmDialog({
      open: true,
      email: user.email,
    });
  };

  const confirmCancelSubscription = async () => {
    setIsCancelling(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        '/cancel-subscription',
        { email: confirmDialog.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSnackbar({
        open: true,
        message: t('abonnementenModal.cancelSubscriptionSuccess'),
        severity: 'success',
      });
      closeAbonnementenModal();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setSnackbar({
        open: true,
        message:
          error.response?.data?.error ||
          t('abonnementenModal.cancelSubscriptionError'),
        severity: 'error',
      });
    } finally {
      setIsCancelling(false);
      setConfirmDialog({ open: false, email: '' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // One-Time Plans
  const oneTimePlans = [
    {
      key: 'child',
      title: t('abonnementenModal.oneTimePlans.child.title'),
      image: '/40_books.webp',
      oldPrice: t('abonnementenModal.oneTimePlans.child.oldPrice'),
      newPrice: t('abonnementenModal.oneTimePlans.child.newPrice'),
      features: t('abonnementenModal.oneTimePlans.child.features', { returnObjects: true }),
      button: (
        <Button variant="contained" sx={{ mt: 1 }} onClick={handleChooseKindbundel}>
          {t('abonnementenModal.oneTimePlans.child.button')}
        </Button>
      ),
    },
    {
      key: 'org',
      title: t('abonnementenModal.oneTimePlans.org.title'),
      image: '/500_books.webp',
      price: t('abonnementenModal.oneTimePlans.org.price'),
      shortFeatures: t('abonnementenModal.oneTimePlans.org.shortFeatures', { returnObjects: true }),
      extraFeatures: t('abonnementenModal.oneTimePlans.org.extraFeatures', { returnObjects: true }),
      button: (
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          href="mailto:info@verhalen-maker.nl"
          sx={{ mt: 1 }}
        >
          {t('abonnementenModal.oneTimePlans.org.button')}
        </Button>
      ),
    },
  ];

  // Subscription Plans
  const subscriptionPlans = [
    {
      key: 'monthly',
      title: t('abonnementenModal.subscriptionPlans.monthly.title'),
      image: '/monthly_license.webp',
      amount: t('abonnementenModal.subscriptionPlans.monthly.amount'),
      interval: t('abonnementenModal.subscriptionPlans.monthly.interval'),
      features: t('abonnementenModal.subscriptionPlans.monthly.features', { returnObjects: true }),
      button: (
        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={() => handleChooseSubscription('monthly_license')}
        >
          {t('abonnementenModal.subscriptionPlans.monthly.button')}
        </Button>
      ),
    },
    {
      key: 'yearly',
      title: t('abonnementenModal.subscriptionPlans.yearly.title'),
      image: '/yearly_license.webp',
      amount: t('abonnementenModal.subscriptionPlans.yearly.amount'),
      interval: t('abonnementenModal.subscriptionPlans.yearly.interval'),
      features: t('abonnementenModal.subscriptionPlans.yearly.features', { returnObjects: true }),
      button: (
        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={() => handleChooseSubscription('yearly_license')}
        >
          {t('abonnementenModal.subscriptionPlans.yearly.button')}
        </Button>
      ),
    },
    // Added Organization Card in Subscriptions Section
    {
      key: 'org',
      title: t('abonnementenModal.oneTimePlans.org.title'),
      image: '/500_books.webp',
      price: t('abonnementenModal.oneTimePlans.org.price'),
      shortFeatures: t('abonnementenModal.oneTimePlans.org.shortFeatures', { returnObjects: true }),
      extraFeatures: t('abonnementenModal.oneTimePlans.org.extraFeatures', { returnObjects: true }),
      button: (
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          href="mailto:info@verhalen-maker.nl"
          sx={{ mt: 1 }}
        >
          {t('abonnementenModal.oneTimePlans.org.button')}
        </Button>
      ),
    },
  ];

  const paymentMethods = [
    { src: '/ideal_icon.png', alt: 'iDEAL' },
    { src: '/paypal_logo.png', alt: 'PayPal' },
    { src: '/visa_logo.webp', alt: 'Visa' },
    { src: '/bancontact_logo.png', alt: 'Bancontact' },
    { src: '/mastercard_logo.png', alt: 'Mastercard' },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Use the translated reviews (if available)
  const currentReview =
    reviews && reviews.length > 0 ? reviews[currentReviewIndex] : null;

  return (
    <AbonnementenModalContext.Provider
      value={{ isModalOpen, openAbonnementenModal, closeAbonnementenModal }}
    >
      {children}

      <Dialog
        open={isModalOpen}
        onClose={closeAbonnementenModal}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            p: 2,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            background: `linear-gradient(
              135deg,
              ${theme.palette.background.paper} 25%,
              ${theme.palette.grey[100]} 50%,
              ${theme.palette.background.paper} 75%
            )`,
            backgroundSize: '400% 400%',
            animation: 'glance 15s ease infinite',
          },
        }}
        TransitionProps={{ appear: false }}
      >
        <IconButton
          aria-label="close"
          onClick={closeAbonnementenModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle sx={{ textAlign: 'center', pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'medium' }}>
            {activeTab === 0
              ? t('abonnementenModal.titleOneTime')
              : t('abonnementenModal.titleSubscription')}
          </Typography>
        </DialogTitle>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label={t('abonnementenModal.tabs.oneTime')} />
            <Tab label={t('abonnementenModal.tabs.subscription')} />
          </Tabs>
        </Box>

        <DialogContent sx={{ pt: 1 }}>
          <>
            {isAuthenticated && userCredits !== null && (
              <Typography
                variant="body2"
                align="left"
                sx={{ mb: 2, color: 'text.secondary' }}
              >
                {t('abonnementenModal.userCredits', {
                  name: userName,
                  credits: userCredits,
                })}
              </Typography>
            )}

            <Grid container spacing={2}>
              {activeTab === 0
                ? oneTimePlans.map((plan) => {
                    const isOrg = plan.key === 'org';
                    const featuresToShow = isOrg ? plan.shortFeatures : plan.features;

                    return (
                      <Grid item xs={12} sm={6} key={plan.key}>
                        <Card
                          variant="outlined"
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            height: '100%',
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={plan.image}
                            alt={plan.title}
                            sx={{ height: 120, borderRadius: 1, objectFit: 'cover' }}
                          />
                          <CardContent sx={{ flexGrow: 1, px: 0 }}>
                            <Typography
                              variant="subtitle1"
                              align="center"
                              sx={{ mt: 2, fontWeight: 'bold' }}
                            >
                              {plan.title}
                            </Typography>

                            {isOrg ? (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ mb: 2 }}
                              >
                                {plan.price}
                              </Typography>
                            ) : (
                              <Box textAlign="center" sx={{ mb: 2 }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    textDecoration: 'line-through',
                                    color: 'text.secondary',
                                    mr: 1,
                                    fontSize: '0.85rem',
                                  }}
                                >
                                  €{plan.oldPrice}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  sx={{ color: 'primary', fontWeight: 'bold' }}
                                >
                                  €{plan.newPrice}
                                </Typography>
                                <Chip
                                  label={t('abonnementenModal.discountLabel')}
                                  color="secondary"
                                  size="small"
                                  sx={{ ml: 1 }}
                                />
                              </Box>
                            )}

                            <Box
                              component="ul"
                              sx={{
                                listStyle: 'none',
                                p: 0,
                                m: 0,
                                fontSize: '0.9rem',
                                color: 'text.secondary',
                              }}
                            >
                              {featuresToShow.map((feat) => (
                                <li key={feat} style={{ marginBottom: 4 }}>
                                  • {feat}
                                </li>
                              ))}
                              {isOrg && (
                                <Collapse in={orgExpanded}>
                                  {plan.extraFeatures?.map((feat) => (
                                    <li key={feat} style={{ marginBottom: 4 }}>
                                      • {feat}
                                    </li>
                                  ))}
                                </Collapse>
                              )}
                            </Box>

                            {isOrg &&
                              plan.extraFeatures &&
                              plan.extraFeatures.length > 0 && (
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => setOrgExpanded((prev) => !prev)}
                                  sx={{ mt: 1 }}
                                >
                                  {orgExpanded
                                    ? t('abonnementenModal.oneTimePlans.org.moreDetailsCollapse')
                                    : t('abonnementenModal.oneTimePlans.org.moreDetailsExpand')}
                                </Button>
                              )}
                          </CardContent>
                          {plan.button}
                        </Card>
                      </Grid>
                    );
                  })
                : subscriptionPlans.map((plan) => {
                    if (plan.key === 'org') {
                      // Render organization card for subscriptions like in One-Time section
                      return (
                        <Grid item xs={12} sm={6} key={plan.key}>
                          <Card
                            variant="outlined"
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              p: 2,
                              height: '100%',
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={plan.image}
                              alt={plan.title}
                              sx={{ height: 120, borderRadius: 1, objectFit: 'cover' }}
                            />
                            <CardContent sx={{ flexGrow: 1, px: 0 }}>
                              <Typography
                                variant="subtitle1"
                                align="center"
                                sx={{ mt: 2, fontWeight: 'bold' }}
                              >
                                {plan.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ mb: 2 }}
                              >
                                {plan.price}
                              </Typography>
                              <Box
                                component="ul"
                                sx={{
                                  listStyle: 'none',
                                  p: 0,
                                  m: 0,
                                  fontSize: '0.9rem',
                                  color: 'text.secondary',
                                }}
                              >
                                {plan.shortFeatures.map((feat) => (
                                  <li key={feat} style={{ marginBottom: 4 }}>
                                    • {feat}
                                  </li>
                                ))}
                                <Collapse in={orgExpanded}>
                                  {plan.extraFeatures?.map((feat) => (
                                    <li key={feat} style={{ marginBottom: 4 }}>
                                      • {feat}
                                    </li>
                                  ))}
                                </Collapse>
                              </Box>
                              {plan.extraFeatures && plan.extraFeatures.length > 0 && (
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => setOrgExpanded((prev) => !prev)}
                                  sx={{ mt: 1 }}
                                >
                                  {orgExpanded
                                    ? t('abonnementenModal.oneTimePlans.org.moreDetailsCollapse')
                                    : t('abonnementenModal.oneTimePlans.org.moreDetailsExpand')}
                                </Button>
                              )}
                            </CardContent>
                            {plan.button}
                          </Card>
                        </Grid>
                      );
                    } else {
                      // Render regular subscription cards (monthly/yearly)
                      return (
                        <Grid item xs={12} sm={6} key={plan.key}>
                          <Card
                            variant="outlined"
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              p: 2,
                              height: '100%',
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={plan.image}
                              alt={plan.title}
                              sx={{ height: 120, borderRadius: 1, objectFit: 'cover' }}
                            />
                            <CardContent sx={{ flexGrow: 1, px: 0 }}>
                              <Typography
                                variant="subtitle1"
                                align="center"
                                sx={{ mt: 2, fontWeight: 'bold' }}
                              >
                                {plan.title}
                              </Typography>

                              <Box textAlign="center" sx={{ mb: 2 }}>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  sx={{ color: 'primary', fontWeight: 'bold' }}
                                >
                                  €{plan.amount}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  component="span"
                                  sx={{ ml: 1 }}
                                >
                                  / {plan.interval}
                                </Typography>
                              </Box>

                              <Box
                                component="ul"
                                sx={{
                                  listStyle: 'none',
                                  p: 0,
                                  m: 0,
                                  fontSize: '0.9rem',
                                  color: 'text.secondary',
                                }}
                              >
                                {plan.features.map((feat) => (
                                  <li key={feat} style={{ marginBottom: 4 }}>
                                    • {feat}
                                  </li>
                                ))}
                              </Box>
                            </CardContent>
                            {plan.button}
                          </Card>
                        </Grid>
                      );
                    }
                  })}
            </Grid>

            {activeTab === 1 && isAuthenticated && (
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCancelSubscription}
                  disabled={isCancelling}
                  startIcon={isCancelling ? <CircularProgress size={20} /> : null}
                >
                  {isCancelling
                    ? t('abonnementenModal.cancelling')
                    : t('abonnementenModal.cancelSubscriptionButton')}
                </Button>
              </Box>
            )}

            <Box sx={{ mt: 4, textAlign: 'center', position: 'relative', height: 100 }}>
              <Slide
                direction="right"
                in={true}
                key={currentReview ? currentReview.name : 'default'}
                timeout={{ enter: 700, exit: 1000 }}
              >
                <Box sx={{ position: 'absolute', width: '100%' }}>
                  <Slide direction="right" in={true} timeout={{ enter: 700, exit: 1000 }}>
                    <Box>
                      {currentReview ? (
                        <>
                          <Typography
                            variant="body1"
                            sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                          >
                            “{currentReview.text}”
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            sx={{ mt: 0.5, color: 'text.secondary' }}
                          >
                            — {currentReview.name}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                        >
                          “{t('abonnementenModal.noReviews')}”
                        </Typography>
                      )}
                    </Box>
                  </Slide>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 1,
                      gap: 0.5,
                    }}
                  >
                    {[...Array(5)].map((_, i) => (
                      <Slide
                        key={i}
                        direction="right"
                        in={true}
                        style={{ transitionDelay: `${i * 300}ms` }}
                        timeout={{ enter: 700, exit: 1000 }}
                        mountOnEnter
                        unmountOnExit
                      >
                        <StarIcon sx={{ color: 'gold' }} />
                      </Slide>
                    ))}
                  </Box>
                </Box>
              </Slide>
            </Box>

            <Box sx={{ textAlign: 'center', mt: 6 }}>
              <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                {t('abonnementenModal.paymentMethodsHeader')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  flexWrap: 'wrap',
                }}
              >
                {paymentMethods.map((method) => (
                  <Box
                    key={method.alt}
                    sx={{
                      width: 36,
                      height: 36,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={method.src} alt={method.alt} width={36} height={36} />
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        </DialogContent>
      </Dialog>

      <MuiDialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, email: '' })}
        aria-labelledby="confirm-cancel-dialog-title"
        aria-describedby="confirm-cancel-dialog-description"
      >
        <DialogTitle id="confirm-cancel-dialog-title">
          {t('abonnementenModal.cancelSubscriptionConfirmTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-cancel-dialog-description">
            {t('abonnementenModal.cancelSubscriptionConfirmDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialog({ open: false, email: '' })}
            color="primary"
          >
            {t('abonnementenModal.cancelSubscriptionConfirmNo')}
          </Button>
          <Button
            onClick={confirmCancelSubscription}
            color="error"
            autoFocus
            disabled={isCancelling}
          >
            {isCancelling ? <CircularProgress size={20} /> : t('abonnementenModal.cancelSubscriptionConfirmYes')}
          </Button>
        </DialogActions>
      </MuiDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </AbonnementenModalContext.Provider>
  );
};

export default AbonnementenModalProvider;
